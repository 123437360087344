/*
// .product-card
*/

@import "../../../scss/variables";
@import "../../../scss/mixins/buttons";
@import "../../../scss/mixins/product-card";
@import "../../../scss/mixins/preloader";
@import "../../../scss/mixins/hacks";
@import "../../../scss/mixins/direction";

.item_overlay {
  height: 100%;
  width: 100%;
  background-color: transparent;
  opacity: 0.4;
  display: block;
  z-index: 1;
  position: absolute;
  margin: 0;
  transition: background-color 0.2s;
}



.product-card__symbol {
  margin-left: 5px;
}

.product-card {
  position: relative;
  flex-direction: column;
  background: $product-card-bg;
  width: 100%;
  flex-shrink: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    border-radius: $product-card-border-radius;
    //  box-shadow: $product-card-default-shadow;
    // transition:  height 1s, background-color 0.5s; /*box-shadow .2s*/
  }

  &:hover::before {
    // box-shadow: $product-card-hover-shadow;
    /*  z-index: 2;
        height: 100%;
        background-color: gray;
        opacity: 0.5;  */
  }

  &:hover .product-card__quickview {
    transition-duration: 0s, 0s;
    background: $product-card-quickview-default-bg;

    &:not(.product-card__quickview--preload) {
      color: $product-card-quickview-default-color;
    }

    &:not(.product-card__quickview--preload):hover {
      transition-duration: 0.2s, 0.2s;
      background: $product-card-quickview-hover-bg;
      color: $product-card-quickview-hover-color;
    }
  }
}

.img_btn_wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  z-index: 3;
  height: 100%;
  width: inherit;
  opacity: 0;
  transition: opacity 1s;
  cursor: pointer;
}

.product-image__body:hover .img_btn_wrapper {
  opacity: 1;
}


@media screen and (min-width: 1024px) {
  .product-card__image {
    //padding: 18px;
    flex-shrink: 0;
    //min-height: 258px;

    &:hover .item_overlay {
      background-color: gray;
    }
  }
  .product-image__default-fms {
    min-height: 260px;
  }
}



@media screen and (max-width: 991px) {
  .img_btn_wrapper {
    z-index: unset;
  }
  .product-image__default-fms {
    max-height: 260px !important;
  }
}


.product-card__quickview {
  z-index: 2;
  position: absolute;
  border: none;
  font-size: 10px;
  text-transform: uppercase;
  fill: currentColor;
  padding: 7px;
  cursor: pointer;
  top: 0;
  background: transparent;
  color: $product-card-quickview-color;
  transition: background-color 0.2s, color 0.2s;

  @include direction {
    #{$inset-inline-end}: 0;
    @include logical-border-radius(0 2px 0 2px);
  }

  &:focus {
    outline: none;
  }
}

.product-card__quickview--preload {
  &:after {
    @include preloader(16px);
  }

  color: transparent;
}


.product-card__info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 8px;
  gap: 2px;

  .product-card__name {
    margin: 0 !important;
  }
}

.product-card__name {
  font-size: 16px;
  height: 20px;
  font-weight: 600;
  flex-grow: 1;
  margin-bottom: 10px;
  overflow: hidden;
  margin-top: 10px;
  color: #303030;

  a {
    color: inherit;
    transition: 0.15s;
  }

  a:hover {
    color: $main-theme-color-light;
  }
}

.product-card-description {
  font-size: 16px;
  font-weight: 300;
  color: #606060;
  height: 25px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.product-card__availability{
  display: none;
}

.product-card__availability-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-card__rating {
  margin-top: 6px;
  display: flex;
}

.product-card__rating-legend {
  font-size: 13px;
  line-height: 1;
  color: $rating-legend-font-color;
  padding-top: 1px;

  @include direction {
    #{$margin-inline-start}: 9px;
  }
}





.product-card__buttons {
  display: inline-block;
  margin-top: 7px;
  float: right;

  //margin-right: 5px;
  >*:first-child {
    //@include direction {
    //    #{$margin-inline-end}: auto;
    //}
  }

  >*+* {
    @include direction {
      #{$margin-inline-start}: 4px;
    }
  }
}

.product-card__addToCart--list {
  display: none;
}

.product-card__features-list {
  list-style: none;
  margin: 10px 0 6px;
  padding: 0;
  color: $product-card-font-alt-color;
  font-size: 13px;
  line-height: 15px;

  li {
    padding: 3px 0 3px;
    position: relative;

    @include direction {
      #{$padding-inline-start}: 12px;
    }
  }

  li::before {
    content: "";
    display: block;
    position: absolute;
    top: 9px;
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    border: 1px solid currentColor;

    @include direction {
      #{$inset-inline-start}: 0;
    }
  }
}

.product-card__badges-list {
  top: 18px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;

  @include direction {
    #{$inset-inline-start}: 18px;
  }
}

.product-card__badge {
  font-size: 11px;
  border-radius: 1.5px;
  letter-spacing: 0.02em;
  line-height: 1;
  padding: 5px 8px 4px;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  margin-bottom: 4px;

  @include direction {
    #{$margin-inline-end}: 4px;
  }
}

.product-card__badge--sale {
  background: $product-card-badge-sale-bg;
  color: $product-card-badge-sale-color;
}

.product-card__badge--new {
  background: $product-card-badge-new-bg;
  color: $product-card-badge-new-color;
}

.product-card__badge--hot {
  background: $product-card-badge-hot-bg;
  color: $product-card-badge-hot-color;
}

.product-card--layout--horizontal {
  @include product-card-horizontal();
}

.product-card--layout--grid {
  @include product-card-grid();

  &.product-card--size--sm {
    @include product-card-grid-sm();
  }

  &.product-card--size--nl {
    @include product-card-grid-nl();
  }

  &.product-card--size--lg {
    @include product-card-grid-lg();
  }
}

.product-card--layout--list {
  @include product-card-list();
}



//////////////// from block-products.scss



//.block-products__list-item {
//  display: flex;
//  .product-card {
//    width: 100%;
//    flex-shrink: 0;
//  }
//}

//.block-products__list-item {
//  .product-card {
//    @include product-card-grid();
//
//    @media (min-width: 480px) {
//      @include product-card-grid-sm();
//    }
//    @media (max-width: 479px) {
//      @include product-card-grid-nl();
//    }
//  }
//}
.product-image__body {
  display: block;
  position: relative;
  //padding-bottom: #{100% * (1 / $product-image-ratio)};
  -webkit-box-shadow: 0 5px 10px 2px rgb(34 60 80 / 20%);
  box-shadow: 0 5px 10px 2px rgb(34 60 80 / 20%);
  width: 100%;
  aspect-ratio: 1/1.5;
  max-height: 359px;
  border-radius: 5px;

  img{
    width: 100% !important;
    object-fit: contain;
    height: 100%;
    display: block;
  }
}

.product-image__img {
  display: block;
  width: 100%;
  //object-fit: scale-down;
  object-fit: contain;
  position: relative;
  padding: 3px 7px;
  max-height: 359px;

  &,
  .owl-carousel .owl-item & {
    @include only-ie {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

.product-card__addToCart {
  background-color: $main-theme-color-light;
  //color: #2c2c2c;
  border: none;
  width: 227px;
  height: 41px;
  margin-bottom: 10px;
  font-size: 16px;
}
.product-card__addToCart-tablet {
  height: max-content;
  width: 100%;
  font-size: 14px;
  margin-top: 5px;
  background-color: $main-theme-color-light;
  //color: #2c2c2c;
  border: none;
}

@media screen and (max-width: 1024px) {
  .hide-for-tablet {
    display: none !important;
  }
}

.show-for-tablet {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .show-for-tablet {
    display: block !important;
  }
}